import axios from 'axios'
import router from '../router/index'
import md5 from 'js-md5'
import Vue from 'vue'
import { Message } from 'element-ui'

axios.defaults.withCredentials = true
// axios.defaults.timeout = 10000

// axios.defaults.baseURL = 'http://192.168.1.200:1112'
// axios.defaults.baseURL = 'https://parsing.t.dazhihui029.com:8089'  //测试域名
// axios.defaults.baseURL = 'https://parsing.p.dazhihui029.com:18089'  //生产域名
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

// 请求拦截
axios.interceptors.request.use(config => {
  const _random = guid().replace(/-/g, "")
  const _ts = new Date().getTime()
  let _data = {}
  if (config.data === undefined) {
    _data = config.params === undefined ? {} : {...config.params}
  } else {
    _data = {...config.data}
  }
  _data.random = _random
  _data.ts = _ts
  config.headers = { 
    ts: _ts,
    random: _random,
    sign: getPwdStr(_data)
  }
  return config

}, error => {
  return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(function (response) {
//   if (response.config.showLoading) {
//     endLoading()
//   }
  return response
}, function (error) {
  if (error.response) {
    if (error.response.status === 100) {
      Message.error('请求失败')
    } else if (error.response.status === 1000) {
      Message.error('login_way错误')
    } else if (error.response.status === 1001) {
      Message.error('token验证失败')
    } else if (error.response.status == 1002) {
      Message.error('token已失效')
    } else if (error.response.status == 1003) {
      Message.error('sign校验失败')
    } else if (error.response.status == 1004) {
      Message.error('请求头格式错误')
    } else if (error.response.status == 1005) {
      Message.error('HTTP请求参数错误')
    } else if (error.response.status == 1006) {
      Message.error('接口未找到')
    } else if (error.response.status == 1007) {
      Message.error('服务器错误')
    } else if (error.response.status == 1008) {
      Message.error('服务器繁忙')
    } else if (error.response.status == 1009) {
      Message.error('异地登录')
    }
  }
  return Promise.reject(error)
})

// get请求
export function get (url, params) {
  let app_key = '?app_key=fnQPdgXQJld7GE6Wgo'
  return new Promise((resolve, reject) => {
    axios.get(url + app_key, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}
// post请求
export function post (url, params) {
  let app_key = '?app_key=fnQPdgXQJld7GE6Wgo'
  return new Promise((resolve, reject) => {
    axios.post(url + app_key, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        endLoading()
        reject(err.data)
      })
  })
}

// put请求
export function put (url, params) {
  let app_key = '?app_key=fnQPdgXQJld7GE6Wgo'
  return new Promise((resolve, reject) => {
    axios.put(url + app_key, params)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

// delete 请求
export function deletefn (url, params) {
  let app_key = '?app_key=fnQPdgXQJld7GE6Wgo'
  return new Promise((resolve, reject) => {
    axios.delete(url + app_key, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}
// sign校验
export function getPwdStr (data) {
  let _data = data
  // console.log(_data)
  _data = sortASCII(_data)
  let str = ''
  for (let i in _data) {
      if (_data[i] !== '' && _data[i] !== null) {
          if (typeof _data[i] === 'string') {
              str += i + '=' + _data[i] + '&'
          } else if (typeof _data[i] === 'number' && _data[i] !== 0) {
              str += i + '=' + String(data[i]) + '&'
          } else if (typeof _data[i] === 'object' && _data[i].length !== 0) {
              str += i + '=' + JSON.stringify((_data[i])) + '&'
          } else if (typeof _data[i] === 'boolean' && _data[i] === true) {
              str += i + '=true' + '&'
          }
      }
  }
  str += "key=TL<dx0_7$(9qwA>XOerJUB&xUOY~?_"
  return md5(str).toUpperCase()
}

/* 对象排序 */
export function sortASCII (obj) {
  var arr = new Array()
  var num = 0
  for (var i in obj) {
    arr[num] = i
    num++
  }
  var sortArr = arr.sort()
  var sortObj = {}
  for (let i in sortArr) {
    sortObj[sortArr[i]] = obj[sortArr[i]]
  }
  return sortObj
}
// guid
export function guid() {
return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
});
}