import { post, get, deletefn, put } from '@/http/http'
/* 中转管理*/
// 中转列表查询
export const transferGet = p => get('api/transfer/list', p)
// 中转删除
export const transferDel = p => deletefn('api/transfer/delete', p)
// 中转添加 
export const transferAdd = p => post('api/transfer/add', p)
// 中转下拉查询
export const transferDrop = p => get('api/transfer/drop', p)


/*厂商管理*/
// 厂商列表查询 
export const vendorGet = p => get('api/manufacturer/list', p)
// 厂商删除
export const vendorDel = p => deletefn('api/manufacturer/delete', p)
// 厂商添加 
export const vendorAdd = p => post('api/manufacturer/add', p)
// 厂商下拉查询
export const vendorChoose = p => get('api/manufacturer/drop', p)


/*参数管理*/
// 参数列表查询 
export const parameterGet = p => get('api/parameter/list', p)
// 参数删除
export const parameterDel = p => deletefn('api/parameter/delete', p)
// 参数添加 
export const parameterAdd = p => post('api/parameter/add', p)
// 参数下拉查询
export const parameterDrop = p => get('api/parameter/drop', p)


/*告警名称管理*/
// 名称列表查询
export const alarmGet = p => get('api/alarmname/list', p)
// 名称删除
export const alarmDel = p => deletefn('api/alarmname/delete', p)
// 名称添加 
export const alarmAdd = p => post('api/alarmname/add', p)
// 名称下拉查询
export const alarmDrop = p => get('api/alarmname/drop', p)
// 名称编辑
export const alarmUpdate = p => put('api/alarmname/update', p)


/*类型管理*/
// 类型列表查询
export const equtypeGet = p => get('api/equtype/list', p)
// 类型删除
export const equtypeDel = p => deletefn('api/equtype/delete', p)
// 类型添加 
export const equtypeAdd = p => post('api/equtype/add', p)
// 类型下拉查询
export const equtypeDrop = p => get('api/equtype/drop', p)
// 类型编辑
export const equtypeUpdate = p => put('api/equtype/update', p)
// 类型关联告警名称查询
export const equtypeAlarm = p => get('api/equtype/alarm', p)
// 类型关联告警名称提交
export const equtypeCommit = p => post('api/equtype/alarm_commit', p)


/*型号管理*/
// 型号列表查询
export const modelGet = p => get('api/modelcode/list', p)
// 型号删除
export const modelDel = p => deletefn('api/modelcode/delete', p)
// 型号添加 
export const modelAdd = p => post('api/modelcode/add', p)
// 型号下拉查询
export const modelDrop = p => get('api/modelcode/drop', p)
// 型号详情查询
export const modelDetail = p => get('api/modelcode/details', p)
// 配置类型下拉查询
export const modelConfigDrop = p => get('api/modelcode/model_config_drop', p)
// 型号编辑
export const modelCodeUpdate = p => put('api/modelcode/update', p)


/*对接管理*/
// 对接列表查询
export const buttGet = p => get('api/buttjoint/list', p)
// 对接删除
export const buttDel = p => deletefn('api/buttjoint/delete', p)
// 对接添加 
export const buttAdd = p => post('api/buttjoint/add', p)
// 对接下拉查询
export const buttDrop = p => get('api/buttjoint/drop', p)
// 对接编辑
export const buttUpdate = p => put('api/buttjoint/update', p)
// 附件提交
export const uploadsFile = p => post('api/system/uploads_file', p)
// 参数查询
export const buttParGet = p => get('api/buttjoint/parameter/get', p)
// 参数提交
export const buttParCommit = p => post('api/buttjoint/parameter/commit', p)
// 标识码查询
export const buttIndetGet = p => get('api/buttjoint/ident/get', p)
// 传输方式下拉查询
export const buttWayDrop = p => get('api/buttjoint/way_drop', p)
// 字段下拉查询
export const buttFieldDrop = p => get('api/buttjoint/field_drop', p)
// 标识码提交
export const buttIndetComt = p => post('api/buttjoint/ident/commit', p)


/*账户管理*/
// 账户登录
export const userLogin = p => post('api/account/login', p)
// 账户侧边查询
export const accoutDrop = p => get('api/account/drop', p)
// 账号添加
export const accoutAdd = p => post('api/account/add', p)
// 账号url修改
export const accoutUrl = p => put('api/account/alarm_url', p)
// 账户权限列表查询
export const accoutAuth = p => get('api/account/auth', p)
// 账号权限添加
export const accoutAuthAdd = p => post('api/account/auth_add', p)
// 账号注销
export const accoutLogout = p => post('api/account/logout', p)
// 账号权限删除
export const authDel = p => deletefn('api/account/auth_del', p)
